import {
  AddAddress,
  CartUpdatedV2,
  ChangeShippingAddress,
} from "@faire/retailer-visitor-shared/events";
import { createCartItemForFixCart } from "@faire/retailer-visitor-shared/lib/fixCartItemsV2";
import { getIsBrandPreview } from "@faire/retailer-visitor-shared/lib/getIsBrandPreview";
import { isLoggedInRetailer } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import { fromPromiseWithOldValue } from "@faire/retailer-visitor-shared/lib/mobxHelpers";
import { stringOrUndefined } from "@faire/retailer-visitor-shared/lib/stringOrUndefined";
import { webSocketUrls } from "@faire/retailer-visitor-shared/lib/webSocketUrls";
import { getCartToken } from "@faire/retailer-visitor-shared/serialized-data/getCartToken";
import {
  IBrandCartForUpdate,
  IBrandCartV2WithSummary,
  ICartItemForUpdate,
  ILeanCartItemV2,
  IUpdateItemsOptions,
  UpdateCartItemsHandler,
} from "@faire/retailer-visitor-shared/stores/domain/CartStore/consts";
import { getCartItemFixes } from "@faire/retailer-visitor-shared/stores/domain/CartStore/fixCart";
import { WebSocketChannelStore } from "@faire/retailer-visitor-shared/stores/WebSocketChannelStore";
import { getLocationOrThrow } from "@faire/web--source/common/globals/getLocation";
import { fulfilledOrStale } from "@faire/web--source/common/instanceUtils";
import { makeObservable } from "@faire/web--source/common/makeObservable";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { isNotUndefined } from "@faire/web--source/common/typescriptUtils";
import { Message } from "@faire/web--source/messenger/MessageChannel/MessageChannel";
import { createStoreHook } from "@faire/web--source/ui/hooks/useStore";
import fixCartIssues from "@faire/web-api--source/endpoints/www/api/v2/carts/cartToken/fix-issues/post";
import getCart, {
  QueryParameters as CartParameters,
} from "@faire/web-api--source/endpoints/www/api/v2/carts/cartToken/get";
import { BrandCartsSortBy } from "@faire/web-api--source/faire/carts/BrandCartsSortBy";
import { IBrandCartV2 } from "@faire/web-api--source/faire/carts/IBrandCartV2";
import { ICartItemV2 } from "@faire/web-api--source/faire/carts/ICartItemV2";
import { ICartSummaryResponseV2 } from "@faire/web-api--source/faire/carts/ICartSummaryResponseV2";
import { ICartV2 } from "@faire/web-api--source/faire/carts/ICartV2";
import { IFixCartItemIssuesRequestV2 } from "@faire/web-api--source/faire/carts/IFixCartItemIssuesRequestV2";
import { IGetCartRequest } from "@faire/web-api--source/faire/carts/IGetCartRequest";
import { IUpdateCartResponseV2 } from "@faire/web-api--source/faire/carts/IUpdateCartResponseV2";
import { IFilterSection } from "@faire/web-api--source/indigofair/data/IFilterSection";
import { IFixCartItemIssueRequest } from "@faire/web-api--source/indigofair/data/IFixCartItemIssueRequest";
import { IShippingMethod } from "@faire/web-api--source/indigofair/data/IShippingMethod";
import { IWebSocketMessage } from "@faire/web-api--source/indigofair/websockets/IWebSocketMessage";
import cloneDeep from "lodash/cloneDeep";
import flatten from "lodash/flatten";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import { computed, action, observable } from "mobx";
import { fromPromise, IPromiseBasedObservable, PENDING } from "mobx-utils";
import qs from "query-string";

export class CheckoutCartStore {
  /**
   * @trackfunction
   */
  static get = singletonGetter(CheckoutCartStore);

  constructor() {
    makeObservable(this);
  }

  private checkoutCartWebSocket: number | undefined = undefined;

  /** CartViewState fields */

  @observable
  private cartSummaryResponse?: IPromiseBasedObservable<
    ICartSummaryResponseV2 | undefined
  >;

  @observable
  private cartResponse?: IPromiseBasedObservable<ICartV2 | undefined>;

  @observable
  private activeBrandCartsResponse?: IPromiseBasedObservable<
    ICartV2 | undefined
  >;

  @observable
  private savedBrandCartsResponse?: IPromiseBasedObservable<
    ICartV2 | undefined
  >;

  @observable
  private initialLoad: boolean = true;

  @observable
  private pendingCartItems: Map<string, ICartItemV2> = new Map();

  @observable
  private forbiddenBrandTokens: string[] = [];

  private websocket: number | undefined = undefined;

  private updateCartFn: UpdateCartItemsHandler | undefined = undefined;

  private brandTokens: string[] =
    stringOrUndefined(qs.parse(getLocationOrThrow().search).brands)?.split(
      ","
    ) ?? [];

  setUpdateCartFn = (updateCartFn: UpdateCartItemsHandler) => {
    this.updateCartFn = updateCartFn;
  };

  private resetAllFields = () => {
    this.updateCartFn = undefined;
    this.brandTokens = [];
    this.cartSummaryResponse = undefined;
    this.cartResponse = undefined;
    this.activeBrandCartsResponse = undefined;
    this.savedBrandCartsResponse = undefined;
    this.initialLoad = true;
    this.pendingCartItems.clear();
  };

  @action
  initCheckout = () => {
    this.checkoutCartWebSocket = WebSocketChannelStore.get()
      .getOrInitChannel(webSocketUrls.retailerNotification())
      ?.subscribe(this.handleDutiesUpdate);

    AddAddress.subscribe(this.refreshCheckoutCart);
    ChangeShippingAddress.subscribe(this.refreshCheckoutCart);

    this.init();
  };

  @action
  cleanupCheckout = () => {
    this.cleanup();
    if (this.checkoutCartWebSocket !== undefined) {
      WebSocketChannelStore.get()
        .getOrInitChannel(webSocketUrls.retailerNotification())
        ?.unsubscribe(this.checkoutCartWebSocket);
    }

    AddAddress.unsubscribe(this.refreshCheckoutCart);
    ChangeShippingAddress.unsubscribe(this.refreshCheckoutCart);
    this.resetAllFields();
  };

  @computed
  get isCheckoutCartLoadingOrUpdating(): boolean {
    return this.isCartLoading;
  }

  @computed
  get isCheckoutCartLoading() {
    return this.isCartLoading && this.initialLoad;
  }

  @computed
  private get cartItems(): ICartItemV2[] | undefined {
    if (this.isCheckoutCartLoadingOrUpdating) {
      return undefined;
    }

    return this.checkoutCartBrands
      .flatMap((brand: IBrandCartV2WithSummary) => {
        return Object.values(brand.cart_items);
      })
      .filter(isNotUndefined);
  }

  @computed
  get cartToken() {
    return getCartToken();
  }

  @computed
  get cartBrandTokens() {
    return this.brandTokens;
  }

  @computed
  get isEmpty(): boolean {
    if (this.cartItems === undefined) {
      return false;
    }

    return isEmpty(this.cartItems);
  }

  @computed
  get checkoutCartBrands(): IBrandCartV2WithSummary[] {
    return this.improvedCartBrands.filter(
      (brand) => brand.state === IBrandCartV2.State.READY_FOR_CHECKOUT
    );
  }

  @action
  refreshCheckoutCart = () => {
    this.refreshCart();
  };

  @action
  refreshCheckoutCartWithShippingMethodType = (
    selectedShippingMethodType?: keyof typeof IShippingMethod.ShippingMethodType
  ) =>
    this.refreshCart({
      selectedShippingMethodType,
    });

  @action
  updateCheckoutItems = async (
    items: ICartItemForUpdate[],
    options: IUpdateItemsOptions
  ) => {
    this.initialLoad = false;
    await this.updateCartData(items, options);
  };

  @action
  updateCheckoutShippingDate = async (
    requestedShipDates: Record<string, number>
  ) => {
    this.initialLoad = false;
    await this.updateCartData(
      [],
      { upltParams: { event: "none" } },
      undefined,
      requestedShipDates
    );
  };

  @action
  fixCheckoutCart = async (items: ILeanCartItemV2[]) => {
    if (!items?.length) {
      return;
    }

    this.initialLoad = false;
    await this.fixCartAndUpdateData(items);
  };

  private handleDutiesUpdate = (message: Message) => {
    if (message.type === IWebSocketMessage.Type.CART_DUTIES_UPDATED) {
      this.refreshCart();
    }
  };

  /** CartViewState methods */

  @action
  private cleanup = () => {
    if (this.websocket !== undefined) {
      WebSocketChannelStore.get()
        .getOrInitChannel(webSocketUrls.retailerNotification())
        ?.unsubscribe(this.websocket);
    }
  };

  @action
  private clearPendingCartItems = () => {
    this.pendingCartItems.clear();
  };

  private getCartRequest = (savedForLater?: boolean): IGetCartRequest =>
    IGetCartRequest.build({
      brand_tokens: this.brandTokens ?? [],
      sort_brand_carts_by: BrandCartsSortBy.UPDATED_AT,
      exclude_alcohol_disclaimer: true,
      saved_for_later: savedForLater,
    });

  @action
  private init = (parallelizeSavedAndActiveBrands?: boolean) => {
    if (!isLoggedInRetailer()) {
      return;
    }

    // Filtering by brand cart tokens takes precedence on the backend
    // over filtering by active or saved brand cart state
    if (!parallelizeSavedAndActiveBrands || this.brandTokens) {
      this.cartResponse = fromPromise(
        getCart(
          getCartToken(),
          CartParameters.build(this.getCartRequest())
        ).catch(() => {
          return undefined;
        })
      );
      return;
    }

    this.savedBrandCartsResponse = fromPromise(
      getCart(
        getCartToken(),
        CartParameters.build(this.getCartRequest(true))
      ).catch(() => {
        return undefined;
      })
    );

    this.activeBrandCartsResponse = fromPromise(
      getCart(getCartToken(), CartParameters.build(this.getCartRequest(false)))
        .then((data) => {
          return data;
        })
        .catch(() => {
          return undefined;
        })
    );
  };

  @action
  private refreshCart = async (options?: {
    selectedShippingMethodType?: keyof typeof IShippingMethod.ShippingMethodType;
  }) => {
    if (!isLoggedInRetailer()) {
      return;
    }
    const oldCart = this.cartResponse?.case<ICartV2>({});

    const promise = getCart(
      getCartToken(),
      CartParameters.build({
        brand_tokens: this.brandTokens ?? [],
        sort_brand_carts_by: BrandCartsSortBy.UPDATED_AT,
        shipping_method_type: options?.selectedShippingMethodType,
        exclude_alcohol_disclaimer: true,
      })
    ).catch(() => {
      return oldCart;
    });

    this.cartResponse = fromPromiseWithOldValue(promise, this.cartResponse);
  };

  private getUpdatedCarts = (
    items: ICartItemForUpdate[],
    updatedCartResponse?: IUpdateCartResponseV2
  ) => {
    const currentFilterSections = cloneDeep(this.filterSections);
    const updatedOrDeletedBrandTokens = new Set();

    items.forEach((item) => {
      updatedOrDeletedBrandTokens.add(item.brand_token);
    });
    updatedCartResponse?.brand_carts.forEach((cart) => {
      updatedOrDeletedBrandTokens.add(cart.brand_token);
    });
    const activeCarts = this.cartData?.brand_carts?.filter(
      (cart) => !updatedOrDeletedBrandTokens.has(cart.brand_token)
    );
    const brandCarts = [
      ...(updatedCartResponse?.brand_carts ?? []),
      ...(activeCarts ?? []),
    ];
    this.clearPendingCartItems();
    return ICartV2.build({
      cart_stats: this.cartData?.cart_stats,
      token: updatedCartResponse?.token,
      brand_carts: brandCarts,
      filter_sections: currentFilterSections,
    });
  };

  private updateCartData = async (
    items: ICartItemForUpdate[],
    options: IUpdateItemsOptions,
    brand_carts?: IBrandCartForUpdate[],
    requested_ship_dates?: Record<string, number>
  ) => {
    const oldCart = this.cartResponse?.case<ICartV2>({});

    const promise = this.updateCartFn?.({
      items,
      allowEmptyItems: true,
      brand_carts,
      requested_ship_dates,
      should_collapse_preorder_groups: undefined,
      upltParams: options.upltParams,
    })
      .then((response) => this.getUpdatedCarts(items, response))
      .catch(() => {
        this.clearPendingCartItems();
        return oldCart;
      });

    if (promise) {
      this.cartResponse = fromPromiseWithOldValue(promise, this.cartResponse);
    }
    return promise;
  };

  private fixCartAndUpdateData = async (items: ILeanCartItemV2[]) => {
    const itemsToFix: IFixCartItemIssueRequest.ICartItemFix[] = flatten(
      items.map((item: ILeanCartItemV2) =>
        getCartItemFixes(createCartItemForFixCart(item))
      )
    );

    const oldCart = this.cartResponse?.case<ICartV2>({});
    const promise = fixCartIssues(
      getCartToken(),
      IFixCartItemIssuesRequestV2.build({
        cart_item_fixes: itemsToFix,
      })
    )
      .then((response) => {
        CartUpdatedV2.publish({});
        return ICartV2.build({
          token: getCartToken(),
          brand_carts: response.brand_carts,
        });
      })
      .catch(() => {
        return oldCart;
      });

    this.cartResponse = fromPromiseWithOldValue(promise, this.cartResponse);
    return promise;
  };

  @computed
  private get activeCartLoading(): boolean {
    return (
      (!this.activeBrandCartsResponse ||
        this.activeBrandCartsResponse.state === PENDING) &&
      this.isFullCartLoading
    );
  }

  // Cart is considered loaded if we've either loaded the full cart, or loaded the
  // active cart, regardless of whether the saved for later cart is still loading
  @computed
  private get isCartLoading(): boolean {
    return this.isFullCartLoading && this.activeCartLoading;
  }

  @computed
  get isFullCartLoading(): boolean {
    return !this.cartResponse || this.cartResponse.state === PENDING;
  }

  @computed
  private get cartSummary(): ICartSummaryResponseV2 | undefined {
    return fulfilledOrStale(this.cartSummaryResponse, "brand_cart_summaries");
  }

  @computed
  private get mergedSavedAndActiveCart(): ICartV2 | undefined {
    const activeCart = fulfilledOrStale(
      this.activeBrandCartsResponse,
      "brand_carts"
    );
    const savedCart = fulfilledOrStale(
      this.savedBrandCartsResponse,
      "brand_carts"
    );

    return ICartV2.build({
      token: activeCart?.token,
      brand_carts: this.mergedBrandCarts(activeCart, savedCart),
      filter_sections: this.mergedFilterSections(activeCart, savedCart),
      cart_stats: activeCart?.cart_stats,
      eori_checkout_content: activeCart?.eori_checkout_content,
    });
  }

  @computed
  private get cartData(): ICartV2 | undefined {
    const fullCartData = fulfilledOrStale(this.cartResponse, "brand_carts");
    if (fullCartData || this.activeCartLoading) {
      return fullCartData;
    }
    return this.mergedSavedAndActiveCart;
  }

  @computed
  private get brandCarts(): IBrandCartV2[] {
    return this.cartData?.brand_carts ?? [];
  }

  @computed
  private get filterSections(): IFilterSection[] {
    return this.cartData?.filter_sections ?? [];
  }

  @computed
  private get improvedCartBrands(): IBrandCartV2WithSummary[] {
    return (this.brandCarts ?? []).map((brand) => {
      return {
        ...brand,
        checkout_summary:
          this.cartSummary?.brand_cart_summaries?.[brand.brand_token ?? ""],
        is_eori_missing: false,
      };
    });
  }

  @computed
  get zipCodeProtectionViolations(): string[] {
    if (getIsBrandPreview()) {
      return [];
    }
    return intersection(
      this.forbiddenBrandTokens,
      this.improvedCartBrands
        .filter((improvedCartBrand) => !improvedCartBrand.saved_for_later)
        .map((brand) => brand.brand_token)
        .filter(isNotUndefined)
    );
  }

  setForbiddenBrandTokens = (forbiddenBrandTokens: string[]) => {
    this.forbiddenBrandTokens = forbiddenBrandTokens;
  };

  private mergeCartArrays = <T>(
    activeItems: T[] | undefined,
    savedItems: T[] | undefined,
    getKey: (item: T) => string | undefined
  ): T[] => {
    const uniqueItemsMap = new Map<string | undefined, T>();

    (activeItems ?? []).forEach((item) => {
      uniqueItemsMap.set(getKey(item), item);
    });

    (savedItems ?? []).forEach((item) => {
      if (!uniqueItemsMap.has(getKey(item))) {
        uniqueItemsMap.set(getKey(item), item);
      }
    });

    return Array.from(uniqueItemsMap.values());
  };

  private mergedBrandCarts = (
    activeCart: ICartV2 | undefined,
    savedCart: ICartV2 | undefined
  ) => {
    return this.mergeCartArrays(
      activeCart?.brand_carts,
      savedCart?.brand_carts,
      (cart) => cart.brand_token
    );
  };

  private mergedFilterSections = (
    activeCart: ICartV2 | undefined,
    savedCart: ICartV2 | undefined
  ) => {
    return this.mergeCartArrays(
      activeCart?.filter_sections,
      savedCart?.filter_sections,
      (section) => section.field_name
    );
  };
}

export const useCheckoutCartStore = createStoreHook(CheckoutCartStore);
