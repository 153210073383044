import { fromPromise, IPromiseBasedObservable } from "mobx-utils";

export const fromPromise_resolve = <T>(value: T): IPromiseBasedObservable<T> =>
  fromPromise.resolve(value) as IPromiseBasedObservable<T>;

export const fromPromiseWithOldValue = <T>(
  newPromise: PromiseLike<T>,
  oldPromise: IPromiseBasedObservable<T> | undefined
): IPromiseBasedObservable<T> =>
  fromPromise(
    newPromise,
    /**
     * Unfortunately the fromPromise() implementation from mobx-utils only
     * uses the old value if oldPromise.state === FULFILLED.
     * https://github.com/mobxjs/mobx-utils/issues/305
     * We want to use the old value even if the oldPromise is still pending.
     * This avoids the race that happens if two requests are started in quick succession.
     */
    fromPromise.resolve(
      oldPromise?.case({
        pending: (stale: T | undefined) => stale,
        fulfilled: (response: T) => response,
        rejected: () => undefined,
      })
    )
  );
