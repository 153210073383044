import { ILeanCartItemV2 } from "@faire/retailer-visitor-shared/stores/domain/CartStore/consts";
import { ICartItemForFixCart } from "@faire/retailer-visitor-shared/stores/domain/CartStore/fixCart";

export const createCartItemForFixCart = (item: ILeanCartItemV2) => {
  return ICartItemForFixCart.build({
    token: item.token,
    product_token: item.product_token,
    product_option_token: item.product_option_token,
    customizations: item.customizations ?? [],
    quantity: item.quantity,
    cart_item_issues: item.issues ?? [],
  });
};
