import { request } from '@faire/web-api--source';
import { IFixCartItemIssuesRequestV2 } from '@faire/web-api--source/faire/carts/IFixCartItemIssuesRequestV2';
import { IFixCartItemIssuesResponseV2 } from '@faire/web-api--source/faire/carts/IFixCartItemIssuesResponseV2';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = (cartToken: string) => {
  return `/api/v2/carts/${cartToken}/fix-issues`;
};

export const build = (
  cartToken: string,
  data: IFixCartItemIssuesRequestV2,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(cartToken),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v2/carts/:cartToken/fix-issues';

/**
 * Product Area: CART
 */
function execute(
  cartToken: string,
  data: IFixCartItemIssuesRequestV2
): Promise<IFixCartItemIssuesResponseV2>;
function execute(
  cartToken: string,
  data: IFixCartItemIssuesRequestV2,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IFixCartItemIssuesResponseV2>>;
function execute(
  cartToken: string,
  data: IFixCartItemIssuesRequestV2,
  options?: EndpointOptions
): Promise<IFixCartItemIssuesResponseV2>;
function execute(
  cartToken: string,
  data: IFixCartItemIssuesRequestV2,
  options?: EndpointOptions
): Promise<
  IFixCartItemIssuesResponseV2 | SuccessResponse<IFixCartItemIssuesResponseV2>
> {
  const { route, ...requestOptions } = build(cartToken, data, options);
  return request<IFixCartItemIssuesResponseV2>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/v2/carts/cartToken/fix-issues/post';
export default execute;
