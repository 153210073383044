import { validateAddress } from "@faire/retailer-visitor-shared/lib/validation";
import { RetailerStore } from "@faire/retailer-visitor-shared/stores/domain/RetailerStore";
import { UserStore } from "@faire/retailer-visitor-shared/stores/domain/UserStore";
import { makeObservable } from "@faire/web--source/common/makeObservable";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { createStoreHook } from "@faire/web--source/ui/hooks/useStore";
import { IAddress } from "@faire/web-api--source/indigofair/data/IAddress";
import isEmpty from "lodash/isEmpty";
import keys from "lodash/keys";
import omit from "lodash/omit";
import { action, computed, observable } from "mobx";

const FIELDS_TO_VALIDATE = [
  "name",
  "address1",
  "city",
  "state",
  "postal_code",
  "phone_number",
];

export class CheckoutShippingAddressStore {
  /**
   * @trackfunction
   */
  static get = singletonGetter(CheckoutShippingAddressStore);

  @observable
  address: Partial<IAddress> = {};
  @observable
  private externalErrors: Record<string, string> = {};

  constructor() {
    makeObservable(this);
  }

  setAddress = (address: Partial<IAddress>) => {
    this.externalErrors = {};
    this.address = address;
  };

  destroy = () => {
    this.address = {};
    this.externalErrors = {};
  };

  @computed
  get isExistingAddress(): boolean {
    return !!this.address?.token;
  }

  /*
   * Validation and Errors
   */
  @computed
  get isValid(): boolean {
    return isEmpty(this.errors);
  }

  @computed
  get errors(): Record<string, string> {
    return { ...this.invalidFormFields, ...this.externalErrors };
  }

  @computed
  private get invalidFormFields() {
    return validateAddress(FIELDS_TO_VALIDATE, this.address);
  }

  @action setErrors = (errors: Record<string, string>) => {
    this.externalErrors = errors;
  };

  @action assign = (address: Partial<IAddress>) => {
    this.clearExternalErrors(keys(address));
    this.address = { ...this.address, ...address };
  };

  @action private clearExternalErrors = (fields: string[]) => {
    this.externalErrors = omit(this.externalErrors, fields);
  };

  @computed
  get addressDefaults(): Partial<IAddress> {
    const retailer = RetailerStore.get().retailer;
    const address: IAddress = retailer?.address ?? IAddress.build({});

    if (!address.phone_number) {
      address.phone_number =
        retailer?.phone_number ?? UserStore.get().phoneNumber;
    }

    const defaults: Partial<IAddress> = {
      ...address,
      country: address.country ?? RetailerStore.get().retailerCountry,
      name: address.name ?? UserStore.get().fullName,
    };

    delete defaults.token;
    return defaults;
  }
}

export const useCheckoutShippingAddressStore = createStoreHook(
  CheckoutShippingAddressStore
);
