import { getOpeningOrderIncentivesInfo } from "@faire/retailer-visitor-shared/serialized-data/getOpeningOrderIncentivesInfo";
import { useSettingDecentralizePageData } from "@faire/retailer-visitor-shared/settings/useSettingDecentralizePageData";
import { useStore } from "@faire/retailer-visitor-shared/stores/domain/OpeningOrderIncentivesContextStore";
import { intlDate } from "@faire/web--source/common/localization/date-fns/intlDate";
import { Storage } from "@faire/web--source/common/Storage";
import { useQuery as useOpeningOrderIncentivesQuery } from "@faire/web-api--source/endpoints/www/api/page-data/opening-order-discount/get-hooks";
import { trackPricingOpeningOrderIncentivesViewOfferBannerView } from "@faire/web-api--source/events/pricing/view/openingOrderIncentivesViewOfferBanner";
import { IFaireMoney } from "@faire/web-api--source/indigofair/data/IFaireMoney";
import { IOpeningOrderDiscountData } from "@faire/web-api--source/indigofair/data/IOpeningOrderDiscountData";
import { differenceInHours } from "date-fns/differenceInHours";
import once from "lodash/once";
import { useCallback, useMemo } from "react";

export interface IOpeningOrderIncentivesProps {
  endDate: string;
  endDateWithTimezone: string;
  discountPercentage: number;
  discountCapAmount?: IFaireMoney;
}

const isDrivenFromEmailStorageKey = "OpeningOrderIncentivesIsDrivenFromEmail";

export const useOpeningOrderIncentives = () => {
  const [{ currentTime }] = useStore(["currentTime"]);
  const shouldDecentralizePageData = useSettingDecentralizePageData();
  const {
    data: {
      opening_order_discount_data = IOpeningOrderDiscountData.build({}),
    } = {},
    isLoading,
    error,
  } = useOpeningOrderIncentivesQuery({
    enabled: shouldDecentralizePageData,
    refetchOnMount: false,
    staleTime: Infinity,
  });

  const {
    discount_bps,
    discount_bps_cap_amount,
    expires_at,
    is_retailer_eligible,
  } = shouldDecentralizePageData
    ? opening_order_discount_data
    : getOpeningOrderIncentivesInfo();

  const showOpeningOrderIncentives = Boolean(
    is_retailer_eligible && expires_at && currentTime < expires_at
  );

  const numberOfHoursRemaining =
    expires_at && differenceInHours(expires_at, currentTime);

  const trackOOIBannerViewEvent = useCallback(
    () => once(trackPricingOpeningOrderIncentivesViewOfferBannerView),
    []
  );

  const openingOrderIncentivesProps = useMemo(() => {
    if (!expires_at || !discount_bps_cap_amount || !discount_bps) {
      return undefined;
    }

    return {
      endDate: intlDate(expires_at, "MMMM d"),
      endDateWithTimezone: intlDate(expires_at, "PPpx"),
      discountPercentage: discount_bps / 100,
      discountCapAmount: discount_bps_cap_amount,
    };
  }, [discount_bps, discount_bps_cap_amount, expires_at]);

  const isDrivenFromMarketingEmail = !!Storage.session.getItem(
    isDrivenFromEmailStorageKey
  );

  return {
    isDrivenFromMarketingEmail,
    numberOfHoursRemaining,
    openingOrderIncentivesProps,
    showOpeningOrderIncentives,
    trackOOIBannerViewEvent,
    isLoading: shouldDecentralizePageData ? isLoading : false,
    error: shouldDecentralizePageData ? error : null,
  };
};
