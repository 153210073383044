import { IFDMarketplaceDiscountData } from "@faire/web-api--source/indigofair/data/IFDMarketplaceDiscountData";
import { isBefore } from "date-fns/isBefore";

export const hasFaireDirectMarketplaceIncentive = (
  faireDirectMarketplaceIncentive: IFDMarketplaceDiscountData | undefined,
  isLoggedInRetailer: boolean
): boolean => {
  if (!faireDirectMarketplaceIncentive) {
    return false;
  }

  const {
    is_retailer_eligible: isRetailerEligible,
    offer_name: offerName,
    expires_at: expiresAt,
  } = faireDirectMarketplaceIncentive;

  const isFaireDirectEvergreenIncentive =
    offerName === "EVERGREEN_MARKETPLACE_DISCOUNT";

  // If expiresAt is not defined, default to incentive not being expired.
  const isExpired = expiresAt ? isBefore(expiresAt, Date.now()) : false;

  return (
    !!isRetailerEligible &&
    isLoggedInRetailer &&
    (isFaireDirectEvergreenIncentive ? true : !isExpired)
  );
};
