import { IFaireMoney } from '@faire/web-api--source/indigofair/data/IFaireMoney';

export namespace ICartItemIssue {
  export enum Reason {
    /**
     * If an option was added as individual unit but later it was changed to a prepack
     * actions: REMOVE
     */
    PRE_PACK_MISSING_COLLECTION_ID = 'PRE_PACK_MISSING_COLLECTION_ID',
    /**
     * If a prepack was added and later deactivated
     * actions: REMOVE
     */
    PRE_PACK_DEACTIVATED = 'PRE_PACK_DEACTIVATED',
    /**
     * If a prepack composition (eg 1-2-2-1) was changed, and either the ratios have changed
     * or one or more items have been added/removed from the prepack configuration.
     * actions: REMOVE, CHANGE_QUANTITY, AUTO_FIX
     */
    PRE_PACK_COMPOSITION_CHANGED = 'PRE_PACK_COMPOSITION_CHANGED',
    /**
     * If the item cannot be purchased by online retailers and you are one
     * actions: REMOVE
     */
    ONLINE_RESTRICTED_ITEM = 'ONLINE_RESTRICTED_ITEM',
    /**
     * If the cart item says there was a tester but there is no longer one
     * actions: ACK, REMOVE
     */
    STALE_TESTER_PRESENT = 'STALE_TESTER_PRESENT',
    /**
     * If the tester price has changed
     * actions: ACK, REMOVE
     */
    TESTER_WITH_STALE_PRICE = 'TESTER_WITH_STALE_PRICE',
    /**
     * If the product is deactivated := brand inactive or product not for sale or option not for sale
     * actions: REMOVE
     */
    PRODUCT_DEACTIVATED = 'PRODUCT_DEACTIVATED',
    /**
     * If the MOQ has changed and product is now invalid
     * actions: REMOVE, CHANGE_QUANTITY, AUTO_FIX
     */
    INVALID_MOQ = 'INVALID_MOQ',
    /**
     * If the price has changed since it was last updated on the cart
     * actions: ACK, REMOVE
     */
    STALE_PRICE = 'STALE_PRICE',
    /**
     * If the unit multiplier (aka case size) was changed and is now invalid
     * actions: REMOVE, CHANGE_QUANTITY, AUTO_FIX
     */
    INVALID_UNIT_MULTIPLIER = 'INVALID_UNIT_MULTIPLIER',
    /**
     * If the product currently requires a customization that is not present on the cart
     * actions: REMOVE
     */
    MISSING_CUSTOMIZATION = 'MISSING_CUSTOMIZATION',
    /**
     * If the item cannot be purchased for retailers on a particular platform and you are one
     * actions: REMOVE
     */
    RETAILER_PLATFORM_RESTRICTED_ITEM = 'RETAILER_PLATFORM_RESTRICTED_ITEM',
    /**
     * If the item is out of stock, retailer cannot buy it and must be removed
     * action: REMOVE
     */
    PRODUCT_OUT_OF_STOCK = 'PRODUCT_OUT_OF_STOCK',
    /**
     * If the item does not have enough availability, retailer cannot buy full amount
     * actions: REMOVE, CHANGE_QUANTITY, AUTO_FIX
     */
    INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
    /**
     * If the brand has elected not to sell in the retailer's region
     */
    REGION_RESTRICTED_ITEM = 'REGION_RESTRICTED_ITEM',
    /**
     * If MOQ has changed andn product is now invalid for an Open Sizing item
     * cannot change quantity since no way to know which option should increase
     * actions: REMOVE, AUTO_FIX
     */
    INVALID_MOQ_OPEN_SIZING = 'INVALID_MOQ_OPEN_SIZING',
    /**
     * Alcohol is restricted in the retailer's region
     * action: Remove
     */
    ALCOHOL_RESTRICTED_REGION = 'ALCOHOL_RESTRICTED_REGION',
    /**
     * Category is restricted in the retailer's region
     * action: REMOVE
     */
    CATEGORY_RESTRICTED_REGION = 'CATEGORY_RESTRICTED_REGION',
    /**
     * If the brand disallows unverified retailers from placing preorders
     * action: REMOVE
     */
    PREORDER_RESTRICTED_UNVERIFIED_RETAILER = 'PREORDER_RESTRICTED_UNVERIFIED_RETAILER',
    /**
     * The retailer has not hit the qualification requirement for a free display product
     * action: REMOVE
     */
    DISPLAY_PRODUCT_UNDER_MINIMUM = 'DISPLAY_PRODUCT_UNDER_MINIMUM',
    /**
     * The retailer has not hit the qualification requirement for a free display product
     * action: REMOVE
     */
    DISPLAY_PRODUCT_NOT_AVAILABLE = 'DISPLAY_PRODUCT_NOT_AVAILABLE',
  }

  export enum Action {
    /**
     * Remove the item from the cart will fix the issue. This is always an option.
     */
    REMOVE = 'REMOVE',
    /**
     * No change is required, just acknowledge some stale information will change.
     */
    ACK = 'ACK',
    /**
     * The current quantity in the cart is invalid and requires changing.
     */
    CHANGE_QUANTITY = 'CHANGE_QUANTITY',
    /**
     * This is the improved ACK. It is used for errors when the BE can make some
     * assumption to fix but, unlike ACK, it does make changes (writes).
     * Clients should only show this as a last resort before REMOVE; if you can fix
     * using a more specific action, do so.
     * For example, the INVALID_MOQ reason, apart from REMOVE, supports both CHANGE_QUANTITY
     * and AUTO_FIX. If clients can support the CHANGE_QUANTITY and let the user pick a correct
     * quantity, that is better UX. If not, they can use the AUTO_FIX option to have it
     * automatically increased to the valid minimum.
     * In general this works as a possible fallback for new actions we might add in the future
     * that won't be supported for all clients.
     */
    AUTO_FIX = 'AUTO_FIX',
  }

  export namespace IUpdatedCartItemInformation {
    /**
     * Return an instance of IUpdatedCartItemInformation with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<IUpdatedCartItemInformation>
    ): IUpdatedCartItemInformation =>
      ({
        ...(partial ?? {}),
      }) as IUpdatedCartItemInformation;
  }

  export interface IUpdatedCartItemInformation {
    price: IFaireMoney | undefined;
    has_tester: boolean | undefined;
    tester_price: IFaireMoney | undefined;
  }

  /**
   * Return an instance of ICartItemIssue with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<ICartItemIssue>): ICartItemIssue =>
    ({
      actions: [],
      ...(partial ?? {}),
    }) as ICartItemIssue;
}

export interface ICartItemIssue {
  /**
   * Precise reason to what kind of issue this is;
   * this is mostly for debugging or if clients want to add some special treatments.
   */
  reason: keyof typeof ICartItemIssue.Reason | undefined;
  /**
   * List of available actions to fix this problem.
   * @protobufOption (faire.common.diffAs) = UNORDERED
   */
  actions: Array<keyof typeof ICartItemIssue.Action>;
  /**
   * Human-friendly message describing the issue.
   * Clients are free to use this as is or add custom treatments for certain actions.
   * (for example they might want to add custom formatting, colours, fonts to the message).
   * This must always be the fallback for unknown reasons, though.
   */
  message: string | undefined;
  /**
   * In the case of stale data issues, this object will contain the updated information.
   * For example, if the issue is STALE_PRICE, this will have the new price.
   * Clients are free to use this information paired with the Reason to build custom looking messages.
   * Also, when acking, this object must be present to verify the acked value.
   */
  updated_cart_information:
    | ICartItemIssue.IUpdatedCartItemInformation
    | undefined;
  /**
   * All issues that have a change quantity action should also return a new suggested quantity that
   * FE can use to fix the issues.
   */
  suggested_quantity: number | undefined;
  /**
   * Token of the cart item with which this issue is associated.
   */
  cart_item_token: string | undefined;
  /**
   * The String used in place of "Update" to auto-fix the cart issue for any of the reasons under the reason enum.
   * @deprecated
   */
  update_string: string | undefined;
}
