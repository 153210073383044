import { request } from '@faire/web-api--source';
import { IOpeningOrderDiscountDataResponse } from '@faire/web-api--source/faire/page_data/IOpeningOrderDiscountDataResponse';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/page-data/opening-order-discount`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/page-data/opening-order-discount';

/**
 * Product Area: INCENTIVES
 */
function execute(): Promise<IOpeningOrderDiscountDataResponse>;
function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IOpeningOrderDiscountDataResponse>>;
function execute(
  options?: EndpointOptions
): Promise<IOpeningOrderDiscountDataResponse>;
function execute(
  options?: EndpointOptions
): Promise<
  | IOpeningOrderDiscountDataResponse
  | SuccessResponse<IOpeningOrderDiscountDataResponse>
> {
  const { route, ...requestOptions } = build(options);
  return request<IOpeningOrderDiscountDataResponse>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/page-data/opening-order-discount/get';
export default execute;
