import { BrandCartsSortBy } from '@faire/web-api--source/faire/carts/BrandCartsSortBy';
import { IRequestPaginationData } from '@faire/web-api--source/indigofair/data/IRequestPaginationData';
import { IShippingMethod } from '@faire/web-api--source/indigofair/data/IShippingMethod';

export namespace IGetCartRequest {
  /**
   * Return an instance of IGetCartRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<IGetCartRequest>): IGetCartRequest =>
    ({
      brand_tokens: [],
      filter_brand_carts_by_keys: [],
      ...(partial ?? {}),
    }) as IGetCartRequest;
}

export interface IGetCartRequest {
  /**
   * @protobufOption (.faire.common.tokenType) = CART
   */
  cart_token: string | undefined;
  /**
   * @protobufOption (.faire.common.tokenType) = BRAND
   */
  brand_tokens: Array<string>;
  sort_brand_carts_by: keyof typeof BrandCartsSortBy | undefined;
  shipping_method_type:
    | keyof typeof IShippingMethod.ShippingMethodType
    | undefined;
  exclude_alcohol_disclaimer: boolean | undefined;
  filter_brand_carts_by_keys: Array<string>;
  /**
   * the page size parameter is a rough estimate of the number of cart items returned
   * actual results can be above or under the given page size since we group by brandCart
   * @deprecated
   */
  pagination_data: IRequestPaginationData | undefined;
  saved_for_later: boolean | undefined;
  should_collapse_preorder_groups: boolean | undefined;
  cursor: string | undefined;
  max_results: number | undefined;
  query: string | undefined;
}
