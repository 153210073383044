import { isNotUndefined } from "@faire/web--source/common/typescriptUtils";
import { ICartItemIssue } from "@faire/web-api--source/indigofair/data/ICartItemIssue";
import { IFixCartItemIssueRequest } from "@faire/web-api--source/indigofair/data/IFixCartItemIssueRequest";
import { IItemCustomization } from "@faire/web-api--source/indigofair/data/IItemCustomization";
import isEqual from "lodash/isEqual";

export interface ICartItemForFixCart {
  token: string | undefined;
  product_token: string | undefined;
  product_option_token: string | undefined;
  customizations: Array<IItemCustomization>;
  quantity: number | undefined;
  cart_item_issues: Array<ICartItemIssue>;
}

export namespace ICartItemForFixCart {
  export const build = (
    partial?: Partial<ICartItemForFixCart> | undefined
  ): ICartItemForFixCart => ({
    token: "",
    product_token: "",
    product_option_token: "",
    customizations: [],
    quantity: 0,
    cart_item_issues: [],
    ...(partial ?? {}),
  });
}

export const getCartItemFixes = (
  item: ICartItemForFixCart
): IFixCartItemIssueRequest.ICartItemFix[] => {
  let includesQuantityFix = false;
  const fixes = item.cart_item_issues.map((issue) => {
    // if the only action available is to remove, then the fix is to remove
    if (isEqual(issue.actions, [ICartItemIssue.Action.REMOVE])) {
      return IFixCartItemIssueRequest.ICartItemFix.build({
        cart_item_token: issue.cart_item_token ?? item.token,
        remove_action: {},
      });
    }

    // acknowledge if we can
    if (issue.actions.includes(ICartItemIssue.Action.ACK)) {
      return IFixCartItemIssueRequest.ICartItemFix.build({
        cart_item_token: issue.cart_item_token ?? item.token,
        ack_action: { acked_value: issue.updated_cart_information },
      });
    }

    // if we cannot acknowledge and we don't have to remove, the issue is quantity related
    // use the suggested quantity passed from the BE to fix. We can only include a single quantity fix per issue
    const fix = !includesQuantityFix
      ? IFixCartItemIssueRequest.ICartItemFix.build({
          cart_item_token: issue.cart_item_token ?? item.token,
          change_quantity_action: {
            new_quantity: issue.suggested_quantity,
          },
        })
      : undefined;
    includesQuantityFix = true;
    return fix;
  });

  return fixes.filter(isNotUndefined);
};
