import { ICartItemIssue } from '@faire/web-api--source/indigofair/data/ICartItemIssue';

export namespace IFixCartItemIssueRequest {
  export namespace ICartItemFix {
    /**
     * Return an instance of ICartItemFix with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (partial?: Partial<ICartItemFix>): ICartItemFix =>
      ({
        ...(partial ?? {}),
      }) as ICartItemFix;
  }

  export interface ICartItemFix {
    /**
     * The token of the cart item to fix.
     */
    cart_item_token: string | undefined;
    remove_action: IFixCartItemIssueRequest.CartItemFixRemoveAction | undefined;
    ack_action: IFixCartItemIssueRequest.ICartItemFixAckAction | undefined;
    change_quantity_action:
      | IFixCartItemIssueRequest.ICartItemFixChangeQuantityAction
      | undefined;
    auto_fix_action:
      | IFixCartItemIssueRequest.CartItemFixAutoFixAction
      | undefined;
  }

  export type CartItemFixRemoveAction = unknown;
  export namespace ICartItemFixAckAction {
    /**
     * Return an instance of ICartItemFixAckAction with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<ICartItemFixAckAction>
    ): ICartItemFixAckAction =>
      ({
        ...(partial ?? {}),
      }) as ICartItemFixAckAction;
  }

  export interface ICartItemFixAckAction {
    /**
     * If this information still matches the current state, the item will be acked.
     * Otherwise the Issue will be updated to reflect the distinctions.
     */
    acked_value: ICartItemIssue.IUpdatedCartItemInformation | undefined;
  }

  export namespace ICartItemFixChangeQuantityAction {
    /**
     * Return an instance of ICartItemFixChangeQuantityAction with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<ICartItemFixChangeQuantityAction>
    ): ICartItemFixChangeQuantityAction =>
      ({
        ...(partial ?? {}),
      }) as ICartItemFixChangeQuantityAction;
  }

  export interface ICartItemFixChangeQuantityAction {
    /**
     * This is the new quantity the user picked.
     */
    new_quantity: number | undefined;
  }

  export type CartItemFixAutoFixAction = unknown;

  /**
   * Return an instance of IFixCartItemIssueRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IFixCartItemIssueRequest>
  ): IFixCartItemIssueRequest =>
    ({
      ...(partial ?? {}),
    }) as IFixCartItemIssueRequest;
}

export interface IFixCartItemIssueRequest {}
