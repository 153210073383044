import { request } from '@faire/web-api--source';
import { IGetConductorComponentResponse } from '@faire/web-api--source/indigofair/data/IGetConductorComponentResponse';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = (componentToken: string) => {
  return `/api/conductor/component/${componentToken}`;
};

export const build = (
  componentToken: string,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(componentToken),
    method: 'GET',
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/conductor/component/:componentToken';

/**
 * Product Area: CONDUCTOR
 */
function execute(
  componentToken: string
): Promise<IGetConductorComponentResponse>;
function execute(
  componentToken: string,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetConductorComponentResponse>>;
function execute(
  componentToken: string,
  options?: EndpointOptions
): Promise<IGetConductorComponentResponse>;
function execute(
  componentToken: string,
  options?: EndpointOptions
): Promise<
  | IGetConductorComponentResponse
  | SuccessResponse<IGetConductorComponentResponse>
> {
  const { route, ...requestOptions } = build(componentToken, options);
  return request<IGetConductorComponentResponse>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/conductor/component/componentToken/get';
export default execute;
