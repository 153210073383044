import execute from './get';
import { IGetConductorComponentResponse } from '@faire/web-api--source/indigofair/data/IGetConductorComponentResponse';
import { EndpointOptions, SuccessResponse } from '@faire/web-api--source/types';
import { WebApiError } from '@faire/web-api--source/WebApiError';
import {
  FetchQueryOptions,
  InvalidateOptions,
  InvalidateQueryFilters,
  QueryFunctionContext,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
  useQuery as useReactQuery,
  useQueryClient,
  useSuspenseQuery as useSuspenseReactQuery,
} from '@tanstack/react-query';
import { useCallback } from 'react';

/**
 * Returns a query key that should be used with useQuery.
 *
 * Disclaimer: Do not use this with useInfiniteQuery, prefer getInfiniteQueryKey.
 */
export function getQueryKey(componentToken: string, options?: EndpointOptions) {
  return [`/api/conductor/component/${componentToken}`, options];
}

/**
 * Returns a query key that should be used with useInfiniteQuery.
 */
export function getInfiniteQueryKey(
  componentToken: string,
  options?: EndpointOptions
): ['infinite', ...ReturnType<typeof getQueryKey>] {
  return ['infinite', ...getQueryKey(componentToken, options)];
}

export function getQueryFn(componentToken: string, options?: EndpointOptions) {
  return ({ signal }: QueryFunctionContext) => {
    const optionsWithSignal = { ...options, signal };
    return execute(componentToken, optionsWithSignal);
  };
}

export function useQuery(
  componentToken: string,
  reactQueryOptions?: Omit<
    UseQueryOptions<
      IGetConductorComponentResponse,
      WebApiError,
      IGetConductorComponentResponse,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<IGetConductorComponentResponse>;
export function useQuery(
  componentToken: string,
  reactQueryOptions?: Omit<
    UseQueryOptions<
      SuccessResponse<IGetConductorComponentResponse>,
      WebApiError,
      SuccessResponse<IGetConductorComponentResponse>,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
  options?: EndpointOptions & { rawResponse: true }
): UseQueryResult<SuccessResponse<IGetConductorComponentResponse>>;
export function useQuery(
  componentToken: string,
  reactQueryOptions?: Omit<
    UseQueryOptions<
      IGetConductorComponentResponse,
      WebApiError,
      IGetConductorComponentResponse,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
  options?: EndpointOptions
): UseQueryResult<IGetConductorComponentResponse>;
export function useQuery(
  componentToken: string,
  reactQueryOptions?:
    | Omit<
        UseQueryOptions<
          IGetConductorComponentResponse,
          WebApiError,
          IGetConductorComponentResponse,
          QueryKey
        >,
        'queryKey' | 'queryFn'
      >
    | Omit<
        UseQueryOptions<
          SuccessResponse<IGetConductorComponentResponse>,
          WebApiError,
          SuccessResponse<IGetConductorComponentResponse>,
          QueryKey
        >,
        'queryKey' | 'queryFn'
      >,
  options?: EndpointOptions
): UseQueryResult<
  | IGetConductorComponentResponse
  | SuccessResponse<IGetConductorComponentResponse>
> {
  return useReactQuery({
    queryKey: getQueryKey(componentToken, options),
    queryFn: getQueryFn(componentToken, options),
    ...(reactQueryOptions as Omit<
      UseQueryOptions<
        IGetConductorComponentResponse,
        WebApiError,
        IGetConductorComponentResponse,
        QueryKey
      >,
      'queryKey' | 'queryFn'
    >),
  });
}

export function useSuspenseQuery(
  componentToken: string,
  suspenseReactQueryOptions?: Omit<
    UseSuspenseQueryOptions<
      IGetConductorComponentResponse,
      WebApiError,
      IGetConductorComponentResponse,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
  options?: EndpointOptions
): UseSuspenseQueryResult<IGetConductorComponentResponse> {
  return useSuspenseReactQuery({
    queryKey: getQueryKey(componentToken, options),
    queryFn: getQueryFn(componentToken, options),
    ...(suspenseReactQueryOptions as Omit<
      UseSuspenseQueryOptions<
        IGetConductorComponentResponse,
        WebApiError,
        IGetConductorComponentResponse,
        QueryKey
      >,
      'queryKey' | 'queryFn'
    >),
  });
}

export function usePrefetchQuery() {
  const queryClient = useQueryClient();
  const prefetch = useCallback(
    (
      componentToken: string,
      fetchQueryOptions?: FetchQueryOptions<
        IGetConductorComponentResponse,
        WebApiError,
        IGetConductorComponentResponse,
        ReturnType<typeof getQueryKey>
      >,
      options?: EndpointOptions
    ) => {
      return queryClient.prefetchQuery({
        queryKey: getQueryKey(componentToken, options),
        queryFn: getQueryFn(componentToken, options),
        ...fetchQueryOptions,
      });
    },
    [queryClient]
  );
  return prefetch;
}

/**
 * Returns a function that invalidates the query for this endpoint.
 * After being invalidated the query will be refetched on the next useQuery call.
 */
export function useInvalidateQuery() {
  const queryClient = useQueryClient();
  const invalidate = useCallback(
    (
      componentToken: string,
      options?: EndpointOptions,
      invalidateQueryFilters?: InvalidateQueryFilters,
      invalidateOptions?: InvalidateOptions
    ) => {
      return queryClient.invalidateQueries(
        {
          queryKey: getQueryKey(componentToken, options),
          ...invalidateQueryFilters,
        },
        invalidateOptions
      );
    },
    [queryClient]
  );
  return invalidate;
}

/**
 * Returns a function that invalidates the infinite query for this endpoint.
 * After being invalidated the query will be refetched on the next useInfiniteQuery call.
 */
export function useInvalidateInfiniteQuery() {
  const queryClient = useQueryClient();
  const invalidate = useCallback(
    (
      componentToken: string,
      options?: EndpointOptions,
      invalidateQueryFilters?: InvalidateQueryFilters,
      invalidateOptions?: InvalidateOptions
    ) => {
      return queryClient.invalidateQueries(
        {
          queryKey: getInfiniteQueryKey(componentToken, options),
          ...invalidateQueryFilters,
        },
        invalidateOptions
      );
    },
    [queryClient]
  );
  return invalidate;
}
