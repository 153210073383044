import { useRetailer } from "@faire/retailer-visitor-shared/hooks/retailer/useRetailer";
import { useSettingMigrateRetailerStore } from "@faire/retailer-visitor-shared/settings/useSettingMigrateRetailerStore";
import { useRetailerStore } from "@faire/retailer-visitor-shared/stores/domain/RetailerStore";
import { addDays } from "date-fns/addDays";
import { useMemo } from "react";

const useIsRetailerCreatedWithinLast30DaysControl = () => {
  const { isRetailerCreatedWithinLast30Days } = useRetailerStore([
    "isRetailerCreatedWithinLast30Days",
  ]);
  return { isRetailerCreatedWithinLast30Days };
};

const useIsRetailerCreatedWithinLast30DaysTreatment = () => {
  const { retailer } = useRetailer();
  const isRetailerCreatedWithinLast30Days = useMemo(() => {
    if (!retailer?.created_at) {
      return false;
    }

    return addDays(new Date(retailer?.created_at), 31) > new Date();
  }, [retailer?.created_at]);

  return { isRetailerCreatedWithinLast30Days };
};

export const useIsRetailerCreatedWithinLast30Days = () => {
  const shouldUseHooksMigration = useSettingMigrateRetailerStore();
  if (shouldUseHooksMigration) {
    // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
    return useIsRetailerCreatedWithinLast30DaysTreatment();
  }
  // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
  return useIsRetailerCreatedWithinLast30DaysControl();
};
