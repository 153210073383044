import { IFixCartItemIssueRequest } from '@faire/web-api--source/indigofair/data/IFixCartItemIssueRequest';

export namespace IFixCartItemIssuesRequestV2 {
  /**
   * Return an instance of IFixCartItemIssuesRequestV2 with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IFixCartItemIssuesRequestV2>
  ): IFixCartItemIssuesRequestV2 =>
    ({
      cart_item_fixes: [],
      brand_tokens_to_return: [],
      ...(partial ?? {}),
    }) as IFixCartItemIssuesRequestV2;
}

/**
 * This allows clients to fix issues in cart items, picking one of the possible actions.
 * If the action is REMOVE, the item will be removed (same effect as making an update cart request).
 * If the actions is ACK, the item will be updated out of stale values.
 * If the action is CHANGE_QUANTITY, the quantity will be changed (as long as it's valid).
 * You can of course have multiple actions for the same cart item, following the rules:
 * * If you have either REMOVE or AUTO_FIX, that must be the only action.
 * * You can only have at most one CHANGE_QUANTITY action.
 * So examples of valid set of actions for the same cart item would be:
 * * [CHANGE_QUANTITY to 5, ACK newValue.price=10.0, ACK newValue.hasTester=false]
 * * [CHANGE_QUANTITY to 1, ACK newValue.testerPrice=1.0]
 * * [REMOVE]
 * * [AUTO_FIX]
 * Of course you can have multiple different cart items in any combination as long as each cart item's
 * actions respect the rules above.
 */
export interface IFixCartItemIssuesRequestV2 {
  cart_item_fixes: Array<IFixCartItemIssueRequest.ICartItemFix>;
  /**
   * If not provided, the response will contain all brands in the cart. Otherwise will only contain these brands.
   */
  brand_tokens_to_return: Array<string>;
  should_collapse_preorder_groups: boolean | undefined;
}
