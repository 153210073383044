import { useCheckoutCartStore } from "@faire/retailer-visitor-shared/components/Checkout/models/store/CheckoutCartStore";
import { useCheckoutShippingAddressStore } from "@faire/retailer-visitor-shared/components/Checkout/models/store/CheckoutShippingAddressStore";
import { useIsLoggedInRetailer } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import { useQuery as useGetConductorComponentFromToken } from "@faire/web-api--source/endpoints/www/api/conductor/component/componentToken/get-hooks";
import { QueryParameters as ConductorComponentQueryParameters } from "@faire/web-api--source/endpoints/www/api/conductor/component/get";
import { useQuery as useGetConductorComponentQuery } from "@faire/web-api--source/endpoints/www/api/conductor/component/get-hooks";
import { ConductorSurface } from "@faire/web-api--source/indigofair/data/ConductorSurface";
import { useInvalidateQuery as useInvalidateConductorComponentQuery } from "@faire/web-api--source/endpoints/www/api/conductor/component/get-hooks";

import { IGetConductorComponentProps, IConductorComponentData } from "./types";
import { useCallback } from "react";

export const useConductorComponentWithCartData = (
  surface: ConductorSurface
) => {
  const { address } = useCheckoutShippingAddressStore(["address"]);
  const { cartToken, cartBrandTokens } = useCheckoutCartStore([
    "cartToken",
    "cartBrandTokens",
  ]);
  const queryParams = ConductorComponentQueryParameters.build({
    surface,
    cart_token: cartToken,
    brand_tokens: cartBrandTokens,
    shipping_address_token: address?.token,
  });

  const conductorData = useConductorComponent({
    ...queryParams,
  });

  return conductorData;
};

export const useConductorComponent = ({
  componentToken,
  ...parameters
}: IGetConductorComponentProps): IConductorComponentData => {
  const isLoggedInRetailer = useIsLoggedInRetailer();
  const queryParams = ConductorComponentQueryParameters.build(parameters);

  const conductorComponentFromToken = useGetConductorComponentFromToken(
    componentToken ?? "",
    {
      enabled: !!componentToken,
    }
  );

  const conductorComponentFallback = useGetConductorComponentQuery(
    queryParams,
    {
      enabled: isLoggedInRetailer && !!queryParams,
      staleTime: Infinity,
      gcTime: Infinity,
    }
  );

  const invalidateConductorComponentQueryBase = useInvalidateConductorComponentQuery();

  const invalidateConductorComponentQuery = useCallback(() => { 
    return invalidateConductorComponentQueryBase(queryParams);
  }, [invalidateConductorComponentQueryBase, queryParams]);

  const activeConductorComponent = componentToken
    ? conductorComponentFromToken
    : conductorComponentFallback;
  const { data, isPending } = activeConductorComponent;

  const conductorSduiViewLayout = data?.component?.sdui_component?.view_layout;
  const conductorBuilderModelName =
    data?.component?.builder_component?.builder_model_name;
  const shouldShowConductor =
    !!conductorSduiViewLayout || !!conductorBuilderModelName;
  // feature name is used in useSiteBannersList hook to identify banner type
  const featureName = data?.component?.feature_name;

  return {
    componentToken: data?.component?.token,
    shouldShowConductor: shouldShowConductor,
    conductorSduiViewLayout: conductorSduiViewLayout,
    conductorBuilderModelName: conductorBuilderModelName,
    isConductorComponentPending: isPending,
    featureName: featureName,
    invalidateConductorComponentQuery: invalidateConductorComponentQuery,
  };
};