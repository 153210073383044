import { hasFaireDirectMarketplaceIncentive } from "@faire/retailer-visitor-shared/hooks/FaireDirect/useFaireDirectMarketplaceIncentive/shared/hasFaireDirectMarketplaceIncentive";
import { mapStyleQuizAnswerToStoreType } from "@faire/retailer-visitor-shared/hooks/firstOrderIncentive/mapStyleQuizAnswerToStoreType";
import { useIsLoggedInRetailer } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import { getFaireDirectMarketplaceDiscountData } from "@faire/retailer-visitor-shared/serialized-data/getFaireDirectMarketplaceDiscountData";
import { useSettingDecentralizePageData } from "@faire/retailer-visitor-shared/settings/useSettingDecentralizePageData";
import { useLocalNum } from "@faire/web--source/common/localization";
import { intlDate } from "@faire/web--source/common/localization/date-fns/intlDate";
import {
  RoundingStrategy,
  moneyOrZero,
  useFormatMoney,
} from "@faire/web--source/common/money";
import { useQuery as useGetFaireDirectMarketplaceDiscountData } from "@faire/web-api--source/endpoints/www/api/faire-direct-marketplace-discount/discount-data/get-hooks";
import { differenceInDays } from "date-fns/differenceInDays";

export const useFaireDirectMarketplaceIncentive = () => {
  const settingDecentralizePageData = useSettingDecentralizePageData();
  const { data: endpointData, refetch } =
    useGetFaireDirectMarketplaceDiscountData({
      /**
       * We only need to refetch this endpoint when
       * landing on post checkout page after an order.
       */
      staleTime: Infinity,
      enabled: settingDecentralizePageData,
    });
  const { localNum } = useLocalNum();
  const { formatMoney } = useFormatMoney();

  const isLoggedInRetailer = useIsLoggedInRetailer();

  const refetchDataAndUpdateStores = refetch;

  const discountData =
    endpointData ??
    (settingDecentralizePageData
      ? undefined
      : getFaireDirectMarketplaceDiscountData());
  const expiresAt = discountData?.expires_at ?? 0;
  const daysRemaining = differenceInDays(expiresAt, Date.now());
  //differenceInDays rounds down, we want to round up so add 1
  const roundedUpDaysRemaining = daysRemaining + 1;
  const lessThanOneDayRemaining = daysRemaining < 1;
  const maxDiscountAmount = discountData?.max_discount_amount;
  const expiresAtDateTime = intlDate(expiresAt, "PPpx");
  const expiresAtDate = intlDate(expiresAt, "MMM d");
  const expiresAtDateWithYear = intlDate(expiresAt, "PP");

  // localized values for use in strings
  const minimumOneDaysRemainingNumber = localNum(roundedUpDaysRemaining);
  const formattedDiscountPercentNumber = localNum(
    (discountData?.discount_bps ?? 0) / 10000,
    {
      style: "percent",
    }
  );
  const formattedMaxDiscountMoney = formatMoney({
    money: moneyOrZero(maxDiscountAmount),
    roundingStrategy: RoundingStrategy.DROP_CENTS,
  });

  const ineligibleBrandTokens = discountData?.in_eligible_brand_tokens ?? [];
  const expiresAtTime = intlDate(discountData?.expires_at ?? 0, "px");
  const offerName = discountData?.offer_name;

  const modalImageDesktop = discountData?.modal_image_desktop;
  const modalImageMobile = discountData?.modal_image_mobile;

  const isFaireDirectMarketplaceIncentive =
    discountData?.offer_name === "FAIRE_DIRECT_MARKETPLACE_DISCOUNT";
  const isFaireDirectEvergreenIncentive =
    discountData?.offer_name === "EVERGREEN_MARKETPLACE_DISCOUNT";

  const storeType = mapStyleQuizAnswerToStoreType(
    discountData?.store_type_for_modal_text
  );

  const hasIncentive = hasFaireDirectMarketplaceIncentive(
    discountData,
    isLoggedInRetailer
  );

  return {
    hasIncentive,
    formattedDiscountPercentNumber,
    formattedMaxDiscountMoney,
    lessThanOneDayRemaining,
    minimumOneDaysRemainingNumber,
    daysRemaining: roundedUpDaysRemaining,
    expiresAtDate,
    expiresAtTime,
    expiresAtDateTime,
    ineligibleBrandTokens,
    expiresAt,
    expiresAtDateWithYear,
    offerName,
    modalImageDesktop,
    modalImageMobile,
    isFaireDirectMarketplaceIncentive,
    isFaireDirectEvergreenIncentive,
    storeType,
    refetchDataAndUpdateStores,
  };
};
