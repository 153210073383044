import { request } from '@faire/web-api--source';
import { IFDMarketplaceDiscountData } from '@faire/web-api--source/indigofair/data/IFDMarketplaceDiscountData';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/faire-direct-marketplace-discount/discount-data`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/faire-direct-marketplace-discount/discount-data';

/**
 * Product Area: FAIRE_DIRECT
 */
function execute(): Promise<IFDMarketplaceDiscountData>;
function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IFDMarketplaceDiscountData>>;
function execute(
  options?: EndpointOptions
): Promise<IFDMarketplaceDiscountData>;
function execute(
  options?: EndpointOptions
): Promise<
  IFDMarketplaceDiscountData | SuccessResponse<IFDMarketplaceDiscountData>
> {
  const { route, ...requestOptions } = build(options);
  return request<IFDMarketplaceDiscountData>(requestOptions);
}
execute[PATH] =
  'endpoints/www/api/faire-direct-marketplace-discount/discount-data/get';
export default execute;
